import type {FC} from 'react';
import React from "react";
import {Card, CardContent, CardHeader, Divider, TextField, Unstable_Grid2 as Grid,} from '@mui/material';
import {FormikProps, getIn} from "formik";
import {Application, PatchedApplication} from "../../api/applications";

interface ApplicationEditEducationProps {
  formik: FormikProps<Application> | FormikProps<PatchedApplication>
}

export const ApplicationEditEducation: FC<ApplicationEditEducationProps> = (props) => {
  const {formik, ...other } = props;

  return (
    <Card {...other}>
      <CardHeader title="Parcours académique" />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            md={6}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.high_school") && getIn(formik.errors, "candidate.high_school"))}
              fullWidth
              multiline
              minRows={5}
              helperText={getIn(formik.touched, "candidate.high_school") && getIn(formik.errors, "candidate.high_school")}
              label="Lycée"
              name="candidate.high_school"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.high_school}
            />
          </Grid>
          <Grid
            md={6}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.college") && getIn(formik.errors, "candidate.college"))}
              fullWidth
              multiline
              minRows={5}
              helperText={getIn(formik.touched, "candidate.college") && getIn(formik.errors, "candidate.college")}
              label="Etudes supérieures"
              name="candidate.college"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.college}
            />
          </Grid>
          <Grid
            md={6}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.native_language") && getIn(formik.errors, "candidate.native_language"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.native_language") && getIn(formik.errors, "candidate.native_language")}
              label="Langue natale"
              name="candidate.native_language"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.native_language}
            />
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.other_language2") && getIn(formik.errors, "candidate.other_language2"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.other_language2") && getIn(formik.errors, "candidate.other_language2")}
              label="Autre langue 2"
              name="candidate.other_language2"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.other_language2}
              sx={{
                mt: 3
              }}
            />
          </Grid>
          <Grid
            md={6}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.other_language") && getIn(formik.errors, "candidate.other_language"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.other_language") && getIn(formik.errors, "candidate.other_language")}
              label="Autre langue"
              name="candidate.other_language"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.other_language}
            />
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.other_language3") && getIn(formik.errors, "candidate.other_language3"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.other_language3") && getIn(formik.errors, "candidate.other_language3")}
              label="Autre langue 3"
              name="candidate.other_language3"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.other_language3}
              sx={{
                mt: 3
              }}
            />
          </Grid>
          <Grid
            md={6}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.current_studies") && getIn(formik.errors, "candidate.current_studies"))}
              fullWidth
              multiline
              minRows={5}
              helperText={getIn(formik.touched, "candidate.current_studies") && getIn(formik.errors, "candidate.current_studies")}
              label="Études en cours"
              name="candidate.current_studies"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.current_studies}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
