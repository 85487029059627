import type {FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogContent, IconButton, Stack, SvgIcon, Typography} from '@mui/material';
import {File, FileDropzone} from './file-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import {Accept} from "react-dropzone";

interface FileUploaderProps {
  onClose: () => void;
  open?: boolean;
  onUpload: (file: File) => void;
  type: "image" | "resume"
}

export const FileUploader: FC<FileUploaderProps> = (props) => {
  const { onClose, onUpload,  open = false, type = "image" } = props;
  const [files, setFiles] = useState<File[]>([]);

  useEffect(
    () => {
      setFiles([]);
    },
    [open]
  );

  const handleDrop = useCallback(
    (newFiles: File[]): void => {
      setFiles((prevFiles) => {
        return [...prevFiles, ...newFiles];
      });
    },
    []
  );

  const handleRemove = useCallback(
    (file: File): void => {
      setFiles((prevFiles) => {
        return prevFiles.filter((_file) => _file.path !== file.path);
      });
    },
    []
  );

  const handleRemoveAll = useCallback(
    (): void => {
      setFiles([]);
    },
    []
  );

  const handleFileUpload = useCallback(
    (): void => {
      onUpload(files[0])
      onClose()
    },
    [files, onUpload, onClose]
  );

  const acceptTypes: Accept = type === "image" ? {'image/*': ['.jpg', '.jpeg', '.png']} : {'application/*': ['.pdf', '.doc', '.docx']}

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Typography variant="h6">
          Uploader un fichier
        </Typography>
        <IconButton
          color="inherit"
          onClick={onClose}
        >
          <SvgIcon>
            <CloseIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <DialogContent>
        <FileDropzone
          maxFiles={1}
          multiple={false}
          accept={acceptTypes}
          files={files}
          onDrop={handleDrop}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          onUpload={handleFileUpload}
        />
      </DialogContent>
    </Dialog>
  );
};

FileUploader.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  open: PropTypes.bool
};
