import type {FC} from 'react';
import React from "react";
import {Card, CardContent, CardHeader, Divider, TextField, Unstable_Grid2 as Grid,} from '@mui/material';
import {FormikProps, getIn} from "formik";
import {Application, PatchedApplication} from "../../api/applications";

interface ApplicationEditApplicationProps {
  formik: FormikProps<Application> | FormikProps<PatchedApplication>
}

export const ApplicationEditApplication: FC<ApplicationEditApplicationProps> = (props) => {
  const {formik, ...other } = props;

  return (
    <Card {...other}>
      <CardHeader title="Motivation" />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.motivation") && getIn(formik.errors, "candidate.motivation"))}
              fullWidth
              multiline
              minRows={5}
              helperText={getIn(formik.touched, "candidate.motivation") && getIn(formik.errors, "candidate.motivation")}
              label="Lettre de motivation"
              name="candidate.motivation"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.motivation}
            />
          </Grid>
          <Grid
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.other_applications") && getIn(formik.errors, "candidate.other_applications"))}
              fullWidth
              multiline
              minRows={5}
              helperText={getIn(formik.touched, "candidate.other_applications") && getIn(formik.errors, "candidate.other_applications")}
              label="Autres candidatures"
              name="candidate.other_applications"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.other_applications}
            />
          </Grid>
          <Grid
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.readings") && getIn(formik.errors, "candidate.readings"))}
              fullWidth
              multiline
              minRows={5}
              helperText={getIn(formik.touched, "candidate.readings") && getIn(formik.errors, "candidate.readings")}
              label="Lectures"
              name="candidate.readings"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.readings}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
