import type {FC} from 'react';
import React from "react";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {FormikProps, getIn} from "formik";
import {getCountries} from "../../utils/countries";
import {Application, PatchedApplication} from "../../api/applications";

interface ApplicationEditContactProps {
  formik: FormikProps<Application> | FormikProps<PatchedApplication>
}

export const ApplicationEditContact: FC<ApplicationEditContactProps> = (props) => {
  const {formik, ...other } = props;

  const {countries} = getCountries()


  return (
    <Card {...other}>
      <CardHeader title="Contact" />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            md={6}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.email") && getIn(formik.errors, "candidate.email"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.email") && getIn(formik.errors, "candidate.email")}
              label="Email"
              name="candidate.email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.email}
            />
          </Grid>
          <Grid
            md={6}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.linkedin") && getIn(formik.errors, "candidate.linkedin"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.linkedin") && getIn(formik.errors, "candidate.linkedin")}
              label="LinkedIn"
              name="candidate.linkedin"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.linkedin}
            />
          </Grid>
          <Grid
            md={6}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.mobile") && getIn(formik.errors, "candidate.mobile"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.mobile") && getIn(formik.errors, "candidate.mobile")}
              label="N° de mobile"
              name="candidate.mobile"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.mobile}
            />
          </Grid>
          <Grid
            md={6}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.phone") && getIn(formik.errors, "candidate.phone"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.phone") && getIn(formik.errors, "candidate.phone")}
              label="N° fixe"
              name="candidate.phone"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.phone}
            />
          </Grid>
          <Grid
            md={8}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.address") && getIn(formik.errors, "candidate.address"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.address") && getIn(formik.errors, "candidate.address")}
              label="Adresse"
              name="candidate.address"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.address}
            />
          </Grid>
          <Grid
            md={4}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.zip_code") && getIn(formik.errors, "candidate.zip_code"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.zip_code") && getIn(formik.errors, "candidate.zip_code")}
              label="Code postal"
              name="candidate.zip_code"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.zip_code}
            />
          </Grid>
          <Grid
            md={6}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.city") && getIn(formik.errors, "candidate.city"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.city") && getIn(formik.errors, "candidate.city")}
              label="Ville"
              name="candidate.city"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.city}
            />
          </Grid>
          <Grid
            md={6}
            xs={12}
          >
            <Autocomplete
              options={countries}
              getOptionLabel={option => option?.name}
              isOptionEqualToValue={(option, value) => option?.code === value?.code}
              value={countries.find((item) => {
                return item.code === formik.values.candidate?.country
              })}
              onChange={(e, value) => {
                formik.setFieldValue("candidate.country", value ? value.code : undefined);
              }}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <Box
                    component="img"
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.name} ({option.code})
                </Box>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  error={Boolean(getIn(formik.touched, "candidate.country") && getIn(formik.errors, "candidate.country"))}
                  fullWidth
                  helperText={getIn(formik.touched, "candidate.country") && getIn(formik.errors, "candidate.country")}
                  label="Pays"
                  name="candidate.country"
                  onBlur={formik.handleBlur}
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
