import type {FC} from 'react';
import React, {useCallback, useState} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  SvgIcon,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {FormikProps, getIn} from "formik";
import {Application, PatchedApplication} from "../../api/applications";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {FileUploader} from "../file-uploader";

interface ApplicationEditProfessionalProps {
  formik: FormikProps<Application> | FormikProps<PatchedApplication>
}

export const ApplicationEditProfessional: FC<ApplicationEditProfessionalProps> = (props) => {
  const {formik,  ...other } = props;

  const [openFileUploader, setOpenFileUploader] = useState<boolean>(false);
  const [resumeFile, setResumeFile] = useState<string | undefined>()

  const handleUploaderOpen = useCallback(
    (): void => {
      setOpenFileUploader(true);
    },
    []
  );

  const handleUploaderClose = useCallback(
    (): void => {
      setOpenFileUploader(false);
    },
    []
  );

  const handleUpload = (file: File): void => {
    const reader = new FileReader()
    reader.onloadend = () => {
      setResumeFile(reader.result as string)
      formik.setFieldValue("candidate.resume", reader.result)
      setOpenFileUploader(false);
    };
    reader.readAsDataURL(file);

  };

  return (
    <Card {...other}>
      <CardHeader title="Parcours professionel" />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            xs={12}
          >
            <Link href={formik.values.candidate?.resume} target="_blank">{formik.values.candidate?.display_name + ".pdf"}</Link>
            <Button
              onClick={handleUploaderOpen}
              size="small"
              startIcon={(
                <SvgIcon>
                  <FileUploadIcon/>
                </SvgIcon>
              )}
              sx={{
                ml: 3
              }}
              variant="contained"
            >
              Modifier le CV
            </Button>
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.resume") && getIn(formik.errors, "candidate.resume"))}
              helperText={getIn(formik.touched, "candidate.resume") && getIn(formik.errors, "candidate.resume")}
              label=""
              name="candidate.resume"
              type="hidden"
              value={formik.values.candidate?.resume}
            >
            </TextField>
          </Grid>
          <Grid
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.last_jobs") && getIn(formik.errors, "candidate.last_jobs"))}
              fullWidth
              multiline
              minRows={5}
              helperText={getIn(formik.touched, "candidate.last_jobs") && getIn(formik.errors, "candidate.last_jobs")}
              label="Derniers positions"
              name="candidate.last_jobs"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.last_jobs}
            />
          </Grid>
          <Grid
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.responsibilities") && getIn(formik.errors, "candidate.responsibilities"))}
              fullWidth
              multiline
              minRows={5}
              helperText={getIn(formik.touched, "candidate.responsibilities") && getIn(formik.errors, "candidate.responsibilities")}
              label="Responsabilités"
              name="candidate.responsibilities"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.responsibilities}
            />
          </Grid>
          <Grid
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.career_objectives") && getIn(formik.errors, "candidate.career_objectives"))}
              fullWidth
              multiline
              minRows={5}
              helperText={getIn(formik.touched, "candidate.career_objectives") && getIn(formik.errors, "candidate.career_objectives")}
              label="Objectifs de carrière"
              name="candidate.career_objectives"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.career_objectives}
            />
          </Grid>
          <Grid
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.strength") && getIn(formik.errors, "candidate.strength"))}
              fullWidth
              multiline
              minRows={5}
              helperText={getIn(formik.touched, "candidate.strength") && getIn(formik.errors, "candidate.strength")}
              label="Forces"
              name="candidate.strength"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.strength}
            />
          </Grid>
          <Grid
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.weaknesses") && getIn(formik.errors, "candidate.weaknesses"))}
              fullWidth
              multiline
              minRows={5}
              helperText={getIn(formik.touched, "candidate.weaknesses") && getIn(formik.errors, "candidate.weaknesses")}
              label="Faiblesses"
              name="candidate.weaknesses"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.weaknesses}
            />
          </Grid>
        </Grid>
      </CardContent>
      <FileUploader
        onClose={handleUploaderClose}
        open={openFileUploader}
        onUpload={handleUpload}
        type="resume"
      />
    </Card>
  );
};
