import type {FC} from 'react';
import React, {useCallback, useEffect, useState} from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Stack,
  SvgIcon,
  TextField,
  Unstable_Grid2 as Grid
} from '@mui/material';
import {FormikProps, getIn} from "formik";
import {DateTime} from "luxon";
import {DateField} from "@mui/x-date-pickers";
import {getCountries} from "../../utils/countries";
import {Application, GenderEnum, PatchedApplication} from "../../api/applications";
import {FileUploader} from "../file-uploader";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from "axios";


interface ApplicationEditBasicProps {
  formik: FormikProps<Application> | FormikProps<PatchedApplication>
}

export const ApplicationEditBasic: FC<ApplicationEditBasicProps> = (props) => {
  const {formik, ...other } = props;
  const {countries, nationalities} = getCountries()
  const [openFileUploader, setOpenFileUploader] = useState<boolean>(false);
  const [applicationPicture, setApplicationPicture] = useState<string | undefined>("/no_avatar.jpg")

  useEffect(() => {
    if (formik.values.candidate?.picture) {
      axios.get(formik.values.candidate?.picture, {responseType: 'blob'}).then(resp => {
        setApplicationPicture(URL.createObjectURL(resp.data))
      }).catch(_ => {
        setApplicationPicture("/no_avatar.jpg")
      })
    }
  }, [formik.values.candidate?.picture])

  const genderizedNationality = (gender: GenderEnum = "H", demonym: {f: string, m: string} ) => {
    return gender === "H" ? demonym.m : demonym.f
  }

  const handleUploaderOpen = useCallback(
    (): void => {
      setOpenFileUploader(true);
    },
    []
  );

  const handleUploaderClose = useCallback(
    (): void => {
      setOpenFileUploader(false);
    },
    []
  );

  const handleUpload = (file: File): void => {
      const reader = new FileReader()
      reader.onloadend = () => {
        setApplicationPicture(reader.result as string ?? "/no_avatar.jpg")
        formik.setFieldValue("candidate.picture", reader.result)
        setOpenFileUploader(false);
      };
      reader.readAsDataURL(file);

  };

  return (
    <Card {...other}>
      <CardHeader title="Identité"/>
      <Divider/>
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            md={5}
            xs={12}

          >
            <Stack
              alignItems="center"
              direction="column"
              spacing={1}
            >
              <Box
                sx={{
                  '& img': {
                    width: 300
                  }
                }}
              >
                <Box component="img" src={applicationPicture} alt="candidate picture" sx={{
                  display: "flex",
                }}/>
              </Box>
              <Button
                onClick={handleUploaderOpen}
                size="small"
                startIcon={(
                  <SvgIcon>
                    <FileUploadIcon/>
                  </SvgIcon>
                )}
                variant="contained"
              >
                Modifier
              </Button>
              <TextField
                error={Boolean(getIn(formik.touched, "candidate.picture") && getIn(formik.errors, "candidate.picture"))}
                helperText={getIn(formik.touched, "candidate.picture") && getIn(formik.errors, "candidate.picture")}
                label=""
                name="candidate.picture"
                type="hidden"
                value={formik.values.candidate?.picture}
              >
              </TextField>
            </Stack>
          </Grid>
          <Grid
            md={7}
            xs={12}
            container
          >
            <Grid
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(getIn(formik.touched, "candidate.firstname") && getIn(formik.errors, "candidate.firstname"))}
                fullWidth
                helperText={getIn(formik.touched, "candidate.firstname") && getIn(formik.errors, "candidate.firstname")}
                label="Prénom"
                name="candidate.firstname"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.candidate?.firstname}
              />
            </Grid>
            <Grid
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(getIn(formik.touched, "candidate.lastname") && getIn(formik.errors, "candidate.lastname"))}
                fullWidth
                helperText={getIn(formik.touched, "candidate.lastname") && getIn(formik.errors, "candidate.lastname")}
                label="Nom"
                name="candidate.lastname"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.candidate?.lastname}
              />
            </Grid>
            <Grid
              md={6}
              xs={12}
            >
              <TextField
                select
                error={Boolean(getIn(formik.touched, "candidate.gender") && getIn(formik.errors, "candidate.gender"))}
                fullWidth
                helperText={getIn(formik.touched, "candidate.gender") && getIn(formik.errors, "candidate.gender")}
                label="Civilité"
                name="candidate.gender"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.candidate?.gender}
                required
              >
                <MenuItem key="H" value="H">
                  Homme
                </MenuItem>
                <MenuItem key="F" value="F">
                  Femme
                </MenuItem>
              </TextField>
            </Grid>
            <Grid
              md={6}
              xs={12}
            >
              <DateField
                format="dd/MM/yyyy"
                name="candidate.birth_date"
                onChange={(value => formik.setFieldValue('candidate.birth_date', value?.toISODate()))}
                onBlur={formik.handleBlur}
                fullWidth
                label="Date de naissance"
                helperText={getIn(formik.touched, "candidate.birth_date") && getIn(formik.errors, "candidate.birth_date")}
                value={formik.values.candidate?.birth_date ? DateTime.fromISO(formik.values.candidate?.birth_date) : null}
                slotProps={{
                  textField: {
                    error: Boolean(getIn(formik.touched, "candidate.birth_date") && getIn(formik.errors, "candidate.birth_date"))
                  }
                }}
              />
            </Grid>
            <Grid
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(getIn(formik.touched, "candidate.birth_place") && getIn(formik.errors, "candidate.birth_place"))}
                fullWidth
                helperText={getIn(formik.touched, "candidate.birth_place") && getIn(formik.errors, "candidate.birth_place")}
                label="Ville de naissance"
                name="candidate.birth_place"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.candidate?.birth_place}
              />
            </Grid>
            <Grid
              md={6}
              xs={12}
            >
              <Autocomplete
                options={countries}
                getOptionLabel={option => `${option.name} (${option.code})`}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                value={countries.find(item => item.code === formik.values.candidate?.birth_country) ?? null}
                onChange={(event, value) => {
                  formik.setFieldValue("candidate.birth_country", value ? value.code : null);
                }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                    <Box
                      component="img"
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.name} ({option.code})
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={Boolean(getIn(formik.touched, "candidate.birth_country") && getIn(formik.errors, "candidate.birth_country"))}
                    fullWidth
                    helperText={getIn(formik.touched, "candidate.birth_country") && getIn(formik.errors, "candidate.birth_country")}
                    label="Pays de naissance"
                    name="candidate.birth_country"
                    onBlur={formik.handleBlur}
                  />
                )}
              />
            </Grid>
            <Grid
              md={6}
              xs={12}
            >
              <Autocomplete
                options={countries}
                getOptionLabel={option => `${genderizedNationality(formik.values.candidate?.gender, nationalities[option.code])} (${option.code})`}
                value={countries.find((item) => {
                  return item.code === formik.values.candidate?.nationality
                }) ?? null}
                onChange={(event, value) => {
                  formik.setFieldValue("candidate.nationality", value ? value.code : null);
                }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                    <Box
                      component="img"
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {genderizedNationality(formik.values.candidate?.gender, nationalities[option.code])} ({option.code})
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={Boolean(getIn(formik.touched, "candidate.nationality") && getIn(formik.errors, "candidate.nationality"))}
                    fullWidth
                    helperText={getIn(formik.touched, "candidate.nationality") && getIn(formik.errors, "candidate.nationality")}
                    label="Nationalité"
                    name="candidate.nationality"
                    onBlur={formik.handleBlur}
                  />
                )}
              />
            </Grid>
            <Grid
              md={6}
              xs={12}
            >
              <Autocomplete
                options={countries}
                getOptionLabel={option => `${genderizedNationality(formik.values.candidate?.gender, nationalities[option.code])} (${option.code})`}
                isOptionEqualToValue={(option, value) => option?.code === value?.code}
                value={formik.values.candidate?.nationality2 ? countries.find((item) => {
                  return item.code === formik.values.candidate?.nationality2
                }) : null}
                onChange={(_, value) => {
                  formik.setFieldValue("candidate.nationality2", value ? value.code : null);
                }}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props} >
                      <Box
                        component="img"
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {genderizedNationality(formik.values.candidate?.gender, nationalities[option.code])} ({option.code})
                    </Box>
                  )
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={Boolean(getIn(formik.touched, "candidate.nationality2") && getIn(formik.errors, "candidate.nationality2"))}
                    fullWidth
                    helperText={getIn(formik.touched, "candidate.nationality2") && getIn(formik.errors, "candidate.nationality2")}
                    label="2e nationalité"
                    name="candidate.nationality2"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <FileUploader
        onClose={handleUploaderClose}
        open={openFileUploader}
        onUpload={handleUpload}
        type="image"
      />
    </Card>
  );
};
