import * as yup from 'yup';
import "yup-phone";

const requiredText = "Le champ est requis."

const candidateCreateSchema = yup.object({
    gender: yup.string().oneOf(["H", "F"]),
    firstname: yup.string().required(requiredText).max(512),
    lastname: yup.string().required(requiredText).max(512),
    birth_date: yup.date().required(requiredText),
    birth_place: yup.string().nullable().max(255),
    birth_country: yup.string().nullable().max(255),
    nationality: yup.string().required(requiredText).max(255),
    nationality2: yup.string().nullable().max(255),
    ine: yup.string().nullable().max(255),
    picture: yup.mixed().nullable(),
    address: yup.string().nullable(),
    zip_code: yup.string().nullable().max(15),
    city: yup.string().nullable().max(255),
    country: yup.string().nullable().max(255),
    phone: yup.string().nullable().max(255),
    mobile: yup.string().nullable().max(255),
    email: yup.string().email("Email invalide").required(requiredText).max(255),
    linkedin: yup.string().url("LinkedIn doit être une url valide.").nullable().max(255),
    high_school: yup.string().nullable(),
    college: yup.string().nullable(),
    native_language: yup.string().nullable(),
    other_language: yup.string().nullable(),
    other_language2: yup.string().nullable(),
    other_language3: yup.string().nullable(),
    current_studies: yup.string().nullable(),
    last_jobs: yup.string().nullable(),
    resume: yup.mixed(),
    career_objectives: yup.string().nullable(),
    weaknesses: yup.string().nullable(),
    strength: yup.string().nullable(),
    responsibilities: yup.string().nullable(),
    other_applications: yup.string().nullable(),
    motivation: yup.string().nullable(),
    ege_known: yup.string().nullable(),
    readings: yup.string().nullable(),
})

const candidateUpdateSchema = yup.object({
    gender: yup.string().oneOf(["H", "F"]),
    firstname: yup.string().max(512),
    lastname: yup.string().max(512),
    birth_date: yup.date().nullable(),
    birth_place: yup.string().nullable().max(255),
    birth_country: yup.string().nullable().max(255),
    nationality: yup.string().nullable().max(255),
    nationality2: yup.string().nullable().max(255),
    ine: yup.string().nullable().max(255),
    picture: yup.mixed().nullable(),
    address: yup.string().nullable(),
    zip_code: yup.string().nullable().max(15),
    city: yup.string().nullable().max(255),
    country: yup.string().nullable().max(255),
    phone: yup.string().nullable().max(255),
    mobile: yup.string().nullable().max(255),
    email: yup.string().email().nullable().max(255),
    linkedin: yup.string().url().nullable().max(255),
    high_school: yup.string().nullable(),
    college: yup.string().nullable(),
    native_language: yup.string().nullable(),
    other_language: yup.string().nullable(),
    other_language2: yup.string().nullable(),
    other_language3: yup.string().nullable(),
    current_studies: yup.string().nullable(),
    last_jobs: yup.string().nullable(),
    resume: yup.mixed(),
    career_objectives: yup.string().nullable(),
    weaknesses: yup.string().nullable(),
    strength: yup.string().nullable(),
    responsibilities: yup.string().nullable(),
    motivation: yup.string().nullable(),
    other_applications: yup.string().nullable(),
    ege_known: yup.string().nullable(),
    readings: yup.string().nullable(),
})

export const applicationCreateSchema =  yup.object({
    state: yup.number().oneOf([0, 1, 2, 3, 4]),
    received_date: yup.date().required(requiredText),
    validated_date: yup.date().nullable(),
    enrolled_date: yup.date().nullable(),
    refused_date: yup.date().nullable(),
    dropped_date: yup.date().nullable(),
    interview_date: yup.date().nullable(),
    notes: yup.string().nullable(),
    candidate: candidateCreateSchema,
    session: yup.number().required(requiredText).positive(requiredText)
})

export const applicationUpdateSchema =  yup.object({
    state: yup.number().oneOf([0, 1, 2, 3, 4]),
    received_date: yup.date(),
    validated_date: yup.date().nullable(),
    enrolled_date: yup.date().nullable(),
    refused_date: yup.date().nullable(),
    dropped_date: yup.date().nullable(),
    interview_date: yup.date().nullable(),
    notes: yup.string().nullable(),
    candidate: candidateUpdateSchema,
    session: yup.number().positive()
})

export const sessionSchema = yup.object({
    major: yup.number().positive(),
    year: yup.number().positive(),
    number: yup.number().positive()
})

export const majorSchema = yup.object({
    name: yup.string().max(255),
    fullname: yup.string().max(512),
})