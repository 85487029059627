import type {FC} from 'react';
import React from "react";
import {Autocomplete, Card, CardContent, CardHeader, Divider, Grid, MenuItem, TextField,} from '@mui/material';
import {FormikProps, getIn} from "formik";
import {EGE_KNOWN_LABELS, getApplicationStates, WHY_APPLY_LABELS} from "../../utils/formatters";
import {useSessionsListQuery} from "../../api/sessions";
import {useMajorsListQuery} from "../../api/majors";
import {DateTimeField} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import {Application, PatchedApplication} from "../../api/applications";

interface ApplicationEditAdminProps {
  formik: FormikProps<Application> | FormikProps<PatchedApplication>
}

export const ApplicationEditAdmin: FC<ApplicationEditAdminProps> = (props) => {
  const {formik, ...other } = props;
  const states = getApplicationStates()

  const {
    data: sessionsRaw = []
  } = useSessionsListQuery()

  const {
    data: majors = []
  } = useMajorsListQuery()

  const sessions = [...sessionsRaw].sort((a, b) => a.major - b.major)

  return (
    <Card {...other}>
      <CardHeader title="Administration" />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              select
              error={Boolean(formik.touched.state && formik.errors.state)}
              fullWidth
              helperText={formik.touched.state && formik.errors.state}
              label="État de la candidature"
              name="state"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.state}
            >
              {states.map(item => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <Autocomplete
              options={sessions}
              groupBy={(option) => majors.find(elt => elt.id === option.major)?.name ?? `${option.major}`}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => formik.setFieldValue('session', value?.id)}
              value={sessions.find(elt => elt.id === formik.values.session) ?? null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(formik.touched.session && formik.errors.session)}
                  fullWidth
                  helperText={formik.touched.session && formik.errors.session}
                  label="Promotion"
                  name="session"
                  onBlur={formik.handleBlur}
                  />
              )}

            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <DateTimeField
              format="dd/MM/yyyy HH:mm"
              name="interview_date"
              onChange={(value => formik.setFieldValue('interview_date',
                value?.toISO()))}
              onBlur={formik.handleBlur}
              fullWidth
              label={"Date de l'entretien"}
              helperText={formik.touched.interview_date && formik.errors.interview_date}
              value={formik.values.interview_date ? DateTime.fromISO(formik.values.interview_date) : null}
              slotProps={{
                textField: {
                  error: Boolean(formik.touched.interview_date && formik.errors.interview_date)
                }
              }}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              error={Boolean(getIn(formik.touched, "candidate.ine") && getIn(formik.errors, "candidate.ine"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.ine") && getIn(formik.errors, "candidate.ine")}
              label="N° INE"
              name="candidate.ine"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.ine}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              select
              error={Boolean(getIn(formik.touched, "candidate.ege_known") && getIn(formik.errors, "candidate.ege_known"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.ege_known") && getIn(formik.errors, "candidate.ege_known")}
              label="Source du lead"
              name="candidate.ege_known"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.ege_known}
            >
              {EGE_KNOWN_LABELS.map((value, index) => {
                return (
                  <MenuItem key={value} value={index}>
                    {value}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              select
              error={Boolean(getIn(formik.touched, "candidate.why_apply") && getIn(formik.errors, "candidate.why_apply"))}
              fullWidth
              helperText={getIn(formik.touched, "candidate.why_apply") && getIn(formik.errors, "candidate.why_apply")}
              label="Pourquoi l'EGE ?"
              name="candidate.why_apply"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.candidate?.why_apply}
            >
              {WHY_APPLY_LABELS.map((value, index) => {
                return (
                  <MenuItem key={value} value={index}>
                    {value}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              error={Boolean(formik.touched.notes && formik.errors.notes)}
              fullWidth
              multiline
              minRows={5}
              helperText={formik.touched.notes && formik.errors.notes}
              label="Notes"
              name="notes"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.notes}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
